<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pa-2 v-if="about">
      <!-- <v-flex xs11 sm6 py-2>
        <v-divider color="#FF8C00"></v-divider>
      </v-flex> -->
      <v-flex xs12 md12 lg12 xl12 pa-2 py-12>
        <span style="color: #000; font-size: 42px;color:#000" class="popbold"
          >About WTI
        </span>
        <!-- <v-img
          contain
          height="60px"
          class="align-center"
          :src="require('./../../../assets/Images/orangebtn.png')"
        >
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2 align-self-center text-center>
              <span class="popbold">ABOUT WTI</span>
            </v-flex>
          </v-layout>
        </v-img> -->
      </v-flex>
      <v-flex xs12 v-if="about.content" pa-2>
        <span v-html="about.content"></span>
      </v-flex>
      <!-- <v-flex xs11 sm6 py-2>
        <v-divider color="#FF8C00"></v-divider>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["about"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
};
</script>
<style>
.rate {
  font-family: sofiaProLight;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
}
.name {
  font-family: sofiaProSemiBold;
  font-size: 20px;
  color: #1a1919;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-select__selections {
  min-height: 10px;
}

/* @keyframes myfirst {
  0% {
    background: red;
    left: 0px;
    top: 0px;
  }
  25% {
    background: yellow;
    left: 200px;
    top: 0px;
  }
}
.anime {
  position: relative;
  animation: myfirst 5s 2;
  animation-direction: alternate;
} */
</style>
