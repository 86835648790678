var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#68D389","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{style:(_vm.$vuetify.breakpoint.name == 'xs'
        ? 'margin-top:-5px'
        : _vm.$vuetify.breakpoint.name == 'sm'
        ? 'margin-top:-5px'
        : _vm.$vuetify.breakpoint.name == 'md'
        ? 'margin-top:-5px'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? 'margin-top:-81px'
        : _vm.$vuetify.breakpoint.name == 'xl'
        ? 'margin-top:-45px'
        : 'margin-top:-1px'),attrs:{"wrap":"","justify-center":""}},[(_vm.banner)?_c('v-flex',{attrs:{"xs12":"","md12":"","lg12":"","xl12":""}},[_c('Banner',{attrs:{"banner":_vm.banner}})],1):_vm._e(),(_vm.about)?_c('v-flex',{attrs:{"xs12":"","md12":"","lg12":"","xl12":"","py-2":""}},[_c('AboutUs',{attrs:{"about":_vm.about}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","md12":"","lg12":"","xl12":"","py-2":"","pb-0":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","lg12":"","xl12":"","pb-0":""}},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('./../../../assets/Images/cbg.jpeg')}},[_c('FeaturedProducts')],1)],1),(_vm.video && _vm.about)?_c('v-flex',{attrs:{"xs12":"","md12":"","lg12":"","xl12":"","pb-16":""}},[_c('Video',{attrs:{"video":_vm.video,"videoContent":_vm.videoContent,"about":_vm.about}})],1):_vm._e(),(_vm.reviews.length > 0)?_c('v-flex',{attrs:{"xs12":"","md12":"","lg12":"","xl12":"","py-2":"","pb-16":""}},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('./../../../assets/Images/mcbg.jpg')}},[_c('CustomerReviews',{attrs:{"reviews":_vm.reviews}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }