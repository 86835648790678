<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout
      wrap
      justify-center
      fill-height
      py-2
      v-if="video && about"
      style="background-color: #ffffff"
      v-scroll="reveal"
      class="reveal"
    >
      <v-flex xs12 sm6 class="hidden-xs-only">
        <v-img :height="$vuetify.breakpoint.name=='xl'?'1380px':'850px'" :src="require('./../../../assets/Images/tigoo.jpg')"></v-img>
      </v-flex>
      <v-flex xs12 sm6 px-4 align-self-center>
        <v-card tile flat color="#ffffff">
          <v-layout
            wrap
            justify-start
            fill-height
            v-scroll="reveal"
            class="reveal"
          >
            <v-flex xs12 sm9 md7 lg5 xl3 py-3 align-self-center text-left v-if="videoContent">
              <!-- <span
                style="color: #000; font-size: 42px; color: #000"
                class="popbold"
                >About WTI
              </span> -->
              <v-img
                contain
                height="75px"
                class="align-center"
                :src="require('./../../../assets/Images/orangebtn.png')"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-2 align-self-center text-center>
                    <span class="bebasregular" style="font-size:32px">
                      {{ videoContent.name }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-flex>
            <v-flex xs12 py-2 align-self-center text-left v-if="videoContent.content">
              <span
                v-html="videoContent.content"
                style="text-align-left !important;"
              ></span>
            </v-flex>
            <v-flex
              text-left
              xs12
              lg9
              v-if="vurl"
              :class="
                $vuetify.breakpoint.name == 'xs'
                  ? 'containerVidXS'
                  : 'containerVid'
              "
            >
              <iframe
                class="responsive-iframe"
                :src="vurl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <!-- <v-flex
        xs12
        v-if="vurl"
        :class="
          $vuetify.breakpoint.name == 'xs' ? 'containerVidXS' : 'containerVid'
        "
      >
        <iframe
          class="responsive-iframe"
          :src="vurl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["video", "about", "videoContent"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      vurl: null,
    };
  },
  beforeMount() {
    if (this.video) {
      var str = this.video;
      var regex = /<iframe.*?src="(.*?)"/;
      var src = regex.exec(str)[1];

      // console.log(src);
      this.vurl = src;
    }
  },
  methods: {
    reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
};
</script>
<style>
.containerVid {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 50.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.containerVidXS {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.reveal {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
</style>
