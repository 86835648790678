<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout
      wrap
      justify-center
      v-if="reviews"
      v-scroll="reveal"
      class="reveal"
    >
      <v-flex
        xs12
        align-self-center
        text-left
        px-0
        px-sm-16
        py-4
        v-if="reviews.length > 0"
      >
        <v-layout wrap>
          <v-flex xs12>
            <v-card tile flat color="transparent">
              <v-layout wrap justify-center py-3>
                <v-flex xs12 sm6 align-self-center text-center px-1>
                  <v-img
                    contain
                    height="75px"
                    class="align-center"
                    :src="require('./../../../assets/Images/orangebtn.png')"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 pa-2 align-self-center text-center>
                        <span
                          class="bebasregular"
                          :style="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'font-size: 32px; color: #000 !important'
                              : 'font-size: 32px; color: #000 !important'
                          "
                        >
                          Customer Reviews</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-img>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        md12
        lg8
        xl12
        align-self-center
        px-sm-12
        v-if="reviews.length > 0"
      >
        <v-layout wrap justify-center>
          <v-flex xs1 align-self-center>
            <span>
              <!-- <v-img
                @click="$refs.prev.click()"
                :disabled="pflag ? false : true"
                :src="require('./../../../assets/Images/left.png')"
                height="105px"
                contain
                style="cursor: pointer"
              ></v-img> -->
              <v-btn
                @click="$refs.prev.click()"
                :disabled="pflag ? false : true"
                fab
                x-large
                color="transparent"
              >
                <v-icon x-large color="#000"> mdi-chevron-left </v-icon>
              </v-btn>
            </span>
          </v-flex>
          <v-flex xs10 lg10>
            <OwlCarousel
              :nav="false"
              :dots="false"
              :autoplay="true"
              :responsive="owlResponsive"
              @initialized="intial"
              @changed="changed"
              :events="['initialized', 'changed']"
            >
              <template slot="prev"
                ><span class="prev" hidden ref="prev">prev</span></template
              >
              <template slot="next"
                ><span class="next" hidden ref="next">next</span></template
              >
              <template v-for="(item, i) in reviews">
                <v-layout wrap justify-center fill-height :key="i">
                  <v-flex
                    xs12
                    lg11
                    pa-2
                    @mouseover="showByIndex = i"
                    @mouseout="showByIndex = null"
                  >
                    <router-link :to="'/product/' + item.product">
                      <v-card
                        tile
                        outlined
                        flat
                        height="350px"
                        class="align-center py-4"
                      >
                        <v-layout wrap justify-center fill-height pb-2 pa-2>
                          <v-flex text-center align-self-center xs12 pt-3>
                            <!-- <br /> -->
                            <span
                              class="popsemibold textified"
                              style="color: #000"
                              >{{ item.comment }}</span
                            >
                          </v-flex>
                          <v-flex text-center align-self-center xs12>
                            <v-rating
                              v-model="item.rating"
                              color="yellow darken-3"
                              background-color="grey darken-1"
                              empty-icon="$ratingFull"
                              half-increments
                              hover
                              small
                              readonly
                            ></v-rating>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </router-link>
                  </v-flex>
                </v-layout>
              </template>
            </OwlCarousel>
          </v-flex>
          <v-flex xs1 align-self-center>
            <span>
              <!-- <v-img
                @click="$refs.next.click()"
                :disabled="nflag ? false : true"
                :src="require('./../../../assets/Images/right.png')"
                height="105px"
                contain
                style="cursor: pointer"
              ></v-img> -->

              <v-btn
                @click="$refs.next.click()"
                :disabled="nflag ? false : true"
                fab
                x-large
                color="transparent"
              >
                <v-icon x-large color="#000"> mdi-chevron-right </v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-layout>

        <v-layout
          wrap
          justify-center
          fill-height
          style="min-height: 50vh"
          v-if="reviews.length < 1"
        >
          <v-flex xs12 text-center align-self-center>
            <span class="itemCaption"> No Reviews Available </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        v-if="reviews.length > 0"
        px-sm-12
        align-self-start
        text-center
      >
        <v-btn text small to="/AllVisitors" class="text-none">
          <span class="popsemibold">View more</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["reviews"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      value: null,
      timeout: 5000,
      msg: null,
      showByIndex: null,
      products: [],
      show: [],
      searchKey: null,
      zIndex: 0,
      items: ["1KG", "2KG", "3KG", "4KG", "5KG"],
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 3, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 3, nav: false },
        2560: { items: 4, nav: false },
      },
      nflag: true,
      pflag: false,
    };
  },
  beforeMount() {
    // this.getData();
  },
  methods: {
    intial(event) {
      console.log("owl carousel initialized");
      console.log("number of slides:", event.item.count);
      console.log("active slide index:", event.item.index);
    },
    changed(event) {
      //
      if (event.item.index === 0) {
        this.pflag = false;
        this.nflag = true;
      } else {
        const currnetel = Math.floor(event.item.index + event.page.size);
        // last
        if (currnetel === event.item.count) {
          this.pflag = true;
          this.nflag = false;
        } else {
          this.pflag = true;
          this.nflag = true;
        }
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/getlist",
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.products = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
};
</script>
<style>
.rate {
  font-family: sofiaProLight;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
}
.name {
  font-family: sofiaProSemiBold;
  font-size: 20px;
  color: #1a1919;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-select__selections {
  min-height: 10px;
}

/* .owl-item.active > div:after {
  content: 'active';
}
.owl-item.center > div:after {
  content: 'center';
}
.owl-item.active.center > div:after {
  content: 'active center';
}
.owl-item > div:after {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
} */

.owl-carousel {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}
.textified {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.reveal {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
</style>
