<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout
      wrap
      justify-center
      v-if="banner"
      :class="
        $vuetify.breakpoint.name == 'xs'
          ? 'banXS'
          : $vuetify.breakpoint.name == 'sm'
          ? 'banXS'
          : $vuetify.breakpoint.name == 'md'
          ? 'banMD'
          : $vuetify.breakpoint.name == 'lg'
          ? 'banLG'
          : $vuetify.breakpoint.name == 'xl'
          ? 'banLG'
          : ''
      "
    >
      <v-flex xs12 md12 lg12 xl12 v-if="banner.length > 0">
        <v-carousel
          cycle
          height="100%"
          hide-delimiter-background
          show-arrows-on-hover
          delimiter-icon="mdi-minus"
          interval="15000"
        >
          <v-carousel-item v-for="(slide, i) in banner" :key="i">
            <v-sheet height="100%">
              <v-row
                class="fill-height hidden-sm-and-down"
                align="start"
                justify="center"
                v-if="slide.coverImage"
              >
                <v-img
                  height="100%"
                  contain
                  width="100%"
                  :src="mediaURL + slide.coverImage"
                  class="align-center"
                >
                  <v-layout wrap justify-end>
                    <v-flex xs11 sm5 lg6 align-self-center text-right pr-6>
                      <v-card
                        tile
                        flat
                        class="pa-4"
                        elevation="0"
                        color="transparent"
                      >
                        <!-- color="rgba(0,0,0,0.4)" -->
                        <span
                          style="
                            color: #ffffff;
                            font-size: 59px;
                            text-shadow: 1px 1px 7px black;
                          "
                          class="popsemibold"
                          >{{ slide.name }}</span
                        >
                        <br />
                        <span
                          style="
                            color: #ffffff;
                            font-size: 29px;
                            text-shadow: 1px 1px 7px black;
                          "
                          class="popsemibold"
                          v-html="slide.bannertext"
                        ></span>
                        <!-- <br /> -->
                        <v-btn
                          elevation="5"
                          :to="'/product/' + slide._id"
                          dark
                          color="#E27A26"
                          large
                          tile
                        >
                          <span
                            style="
                              font-size: 40px;
                              text-shadow: 1px 1px 7px black;
                            "
                            class="popsemibold"
                            >BUY NOW</span
                          >
                        </v-btn>
                      </v-card>
                    </v-flex>
                    <v-flex sm1>
                      <v-spacer></v-spacer>
                    </v-flex>
                  </v-layout>
                </v-img>
              </v-row>
              <v-row
                class="fill-height hidden-md-and-up"
                align="start"
                justify="center"
                v-if="slide.coverImage"
              >
                <v-img
                  height="350px"
                  cover
                  width="100%"
                  :src="mediaURL + slide.coverImage"
                  class="align-center"
                >
                  <v-layout wrap justify-end>
                    <v-flex xs11 sm5 lg3 align-self-center text-left>
                      <span
                        style="
                          color: #ffffff;
                          font-size: 45px;
                          text-shadow: 1px 1px 3px black;
                        "
                        class="popsemibold"
                        >{{ slide.name }}</span
                      >
                      <br />
                      <span
                        style="
                          color: #ffffff;
                          font-size: 22px;
                          text-shadow: 1px 1px 2px black;
                        "
                        class="popsemibold"
                        v-html="slide.bannertext"
                      ></span>
                      <!-- <br /> -->
                      <v-btn
                        elevation="5"
                        :to="'/product/' + slide._id"
                        dark
                        color="#E27A26"
                        large
                        tile
                      >
                        <span
                          style="
                            font-size: 32px;
                            text-shadow: 1px 1px 2px black;
                          "
                          class="popsemibold"
                          >BUY NOW</span
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-img>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  props: ["banner"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      value: null,
      timeout: 5000,
      msg: null,
      showByIndex: null,
    };
  },
};
</script>
<style>
.rate {
  font-family: sofiaProLight;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
}
.name {
  font-family: sofiaProSemiBold;
  font-size: 20px;
  color: #1a1919;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-select__selections {
  min-height: 10px;
}

.banLG {
  margin-top: -6px !important;
  margin-left: -2px;
  margin-right: -2px;
}
.banMD {
  margin-top: -10px !important;
  margin-left: -2px;
  margin-right: -2px;
}
.banXS {
  margin-top: -18px !important;
  margin-left: -2px;
  margin-right: -4px;
}
</style>
