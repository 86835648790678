<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center v-scroll="reveal" class="reveal">
      <v-flex xs12 align-self-center text-left px-0 px-sm-16 py-4>
        <v-layout wrap>
          <v-flex xs12>
            <v-card tile flat color="transparent">
              <v-layout wrap py-3>
                <v-flex xs12>
                  <v-img
                    contain
                    height="75px"
                    class="align-center"
                    :src="require('./../../../assets/Images/orangebtn.png')"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 pa-2 align-self-center text-center>
                        <span class="bebasregular" style="font-size: 32px"
                          >FEATURED PRODUCTS</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-img>
                </v-flex>
                <!-- <v-flex xs12 sm6 align-self-center text-left px-2>
                  <span
                    class="popsemibold"
                    style="font-size: 20px; color: white"
                    >Featured Products</span
                  >
                </v-flex> -->
                <!-- <v-flex xs12 sm6 align-self-center text-right px-1>
                  <v-btn dark tile x-small plain :ripple="false">
                    <span>
                      <v-icon
                        @click="$refs.prev.click()"
                        :color="pflag ? '#fb641b' : 'black'"
                        :disabled="pflag ? false : true"
                        >mdi-arrow-left-drop-circle-outline</v-icon
                      >
                    </span>
                    &nbsp;
                    <router-link to="/Products">
                      <span
                        class="popregular"
                        style="font-size: 14px; color: white"
                        >VIEW ALL COLLECTION
                      </span>
                    </router-link>

                    &nbsp;
                    <span>
                      <v-icon
                        @click="$refs.next.click()"
                        :color="nflag ? '#fb641b' : 'black'"
                        :disabled="nflag ? false : true"
                        >mdi-arrow-right-drop-circle-outline</v-icon
                      >
                    </span>
                  </v-btn>
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        md12
        lg12
        xl12
        align-self-center
        px-sm-12
        v-if="products.length > 0"
      >
        <v-layout wrap justify-center>
          <v-flex xs1 align-self-center>
            <span>
              <v-btn
                @click="$refs.prev.click()"
                :disabled="pflag ? false : true"
                fab
                x-large
                color="transparent"
              >
                <v-icon x-large color="#000"> mdi-chevron-left </v-icon>
              </v-btn>
              <!-- <v-img
                @click="$refs.prev.click()"
                :disabled="pflag ? false : true"
                :src="require('./../../../assets/Images/left.png')"
                height="105px"
                contain
                style="cursor: pointer"
              ></v-img> -->
            </span>
          </v-flex>
          <v-flex xs10 lg10>
            <OwlCarousel
              :nav="false"
              :dots="false"
              :responsive="owlResponsive"
              @initialized="intial"
              @changed="changed"
              :events="['initialized', 'changed']"
            >
              <template slot="prev"
                ><span class="prev" hidden ref="prev">prev</span></template
              >
              <template slot="next"
                ><span class="next" hidden ref="next">next</span></template
              >
              <template v-for="(item, i) in products">
                <v-layout wrap justify-center :key="i">
                  <v-flex
                    xs12
                    pa-2
                    @mouseover="showByIndex = i"
                    @mouseout="showByIndex = null"
                  >
                    <router-link :to="'/product/' + item._id">
                      <v-card tile flat class="pb-4">
                        <v-layout wrap justify-center pb-2>
                          <v-flex
                            xs12
                            pa-0
                            align-self-start
                            v-if="item.images[0]"
                          >
                            <v-img
                              cover
                              :src="mediaURL + item.images[0]"
                              height="250px"
                            >
                              <template v-slot:placeholder>
                                <ImageLoader />
                              </template>
                            </v-img>
                          </v-flex>
                          <v-flex xs12 pa-2 v-else>
                            <v-img
                              contain
                              :src="
                                require('./../../../assets/Images/no-image.png')
                              "
                              height="250px"
                            >
                              <template v-slot:placeholder>
                                <ImageLoader />
                              </template>
                            </v-img>
                          </v-flex>
                          <!-- <v-flex xs12> </v-flex> -->
                          <v-flex text-center xs12 pt-3>
                            <router-link :to="'/product/' + item._id">
                              <!-- <span
                        style="
                          font-family: ibmlight;
                          color: #404040;
                          font-size: 14px;
                        "
                        >WTI</span
                      > -->
                              <span class="popregular" style="color: #696969">{{
                                item.productType.productType
                              }}</span>
                              <br />
                              <span class="popsemibold" style="color: #000">{{
                                item.name
                              }}</span>
                            </router-link>
                          </v-flex>
                          <v-flex text-center xs12>
                            <v-rating
                              v-model="item.averagerating"
                              color="orange"
                              background-color="grey darken-1"
                              empty-icon="$ratingFull"
                              half-increments
                              hover
                              small
                              readonly
                            ></v-rating>
                          </v-flex>
                          <v-flex text-center xs12>
                            <span
                              style="
                                font-size: 16px;
                                font-family: poppinssemibold;
                                color: #b12704;
                              "
                            >
                              Rs. {{ item.price }}
                            </span>
                          </v-flex>
                          <v-flex text-center xs12 v-if="item.rating">
                            <v-rating
                              color="warning"
                              hover
                              length="5"
                              readonly
                              :value="item.rating"
                            ></v-rating>
                          </v-flex>
                          <!-- <v-flex xs12 align-self-center pa-4>
                        <router-link :to="'/product/' + item._id">
                          <v-layout wrap justify-center>
                            <v-flex xs8 align-self-center>
                              <v-btn tile x-small dark color="orange">
                                <span
                                  style="color: #0d5e23"
                                  class="ibmsemibold"
                                >
                                  View more..
                                </span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </router-link>
                      </v-flex> -->
                        </v-layout>
                      </v-card>
                    </router-link>
                  </v-flex>
                </v-layout>
              </template>
            </OwlCarousel>
          </v-flex>
          <v-flex xs1 align-self-center>
            <span>
              <v-btn
                @click="$refs.next.click()"
                :disabled="nflag ? false : true"
                fab
                x-large
                color="transparent"
              >
                <v-icon x-large color="#000"> mdi-chevron-right </v-icon>
              </v-btn>
              <!-- <v-img
                @click="$refs.next.click()"
                :disabled="nflag ? false : true"
                :src="require('./../../../assets/Images/right.png')"
                height="105px"
                contain
                style="cursor:pointer"
              ></v-img> -->
            </span>
          </v-flex>
          <v-flex
            xs12
            v-if="products.length > 0"
            px-sm-12
            align-self-start
            text-center
          >
            <v-btn text small to="/Products" class="text-none">
              <span class="popsemibold">View more</span>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-layout
          wrap
          justify-center
          fill-height
          style="min-height: 50vh"
          v-if="products.length < 1"
        >
          <v-flex xs12 text-center align-self-center>
            <span class="itemCaption"> No Products Available </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      value: null,
      timeout: 5000,
      msg: null,
      showByIndex: null,
      products: [],
      show: [],
      searchKey: null,
      zIndex: 0,
      items: ["1KG", "2KG", "3KG", "4KG", "5KG"],
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 3, nav: false },
        960: { items: 4, nav: false },
        1264: { items: 5, nav: false },
        2560: { items: 6, nav: false },
      },
      nflag: true,
      pflag: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    intial(event) {
      console.log("owl carousel initialized");
      console.log("number of slides:", event.item.count);
      console.log("active slide index:", event.item.index);
    },
    changed(event) {
      //
      if (event.item.index === 0) {
        this.pflag = false;
        this.nflag = true;
      } else {
        const currnetel = Math.floor(event.item.index + event.page.size);
        // last
        if (currnetel === event.item.count) {
          this.pflag = true;
          this.nflag = false;
        } else {
          this.pflag = true;
          this.nflag = true;
        }
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/getlist",
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.products = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
};
</script>
<style>
.rate {
  font-family: sofiaProLight;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
}
.name {
  font-family: sofiaProSemiBold;
  font-size: 20px;
  color: #1a1919;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-select__selections {
  min-height: 10px;
}

/* .owl-item.active > div:after {
  content: 'active';
}
.owl-item.center > div:after {
  content: 'center';
}
.owl-item.active.center > div:after {
  content: 'active center';
}
.owl-item > div:after {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
} */

.owl-carousel {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}

.reveal {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
</style>
