<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout
      wrap
      justify-center
      :style="
        $vuetify.breakpoint.name == 'xs'
          ? 'margin-top:-5px'
          : $vuetify.breakpoint.name == 'sm'
          ? 'margin-top:-5px'
          : $vuetify.breakpoint.name == 'md'
          ? 'margin-top:-5px'
          : $vuetify.breakpoint.name == 'lg'
          ? 'margin-top:-81px'
          : $vuetify.breakpoint.name == 'xl'
          ? 'margin-top:-45px'
          : 'margin-top:-1px'
      "
    >
      <v-flex xs12 md12 lg12 xl12 v-if="banner">
        <Banner :banner="banner" />
      </v-flex>
      <v-flex xs12 md12 lg12 xl12 py-2 v-if="about">
        <AboutUs :about="about" />
      </v-flex>
      <v-flex xs12 md12 lg12 xl12 py-2 pb-0>
        <!-- <v-img
          :src="require('./../../../assets/Images/sibg.png')"
          class="align-center"
        > -->
          <v-layout wrap justify-center>
            <v-flex xs12 md12 lg12 xl12 pb-0>
              <v-img
                :src="require('./../../../assets/Images/cbg.jpeg')"
                class="align-center"
              >
                <FeaturedProducts />
              </v-img>
            </v-flex>
            <v-flex xs12 md12 lg12 xl12  pb-16 v-if="video && about">
              <Video :video="video" :videoContent="videoContent" :about="about" />
            </v-flex>

            <v-flex xs12 md12 lg12 xl12 py-2 pb-16 v-if="reviews.length > 0">
              <v-img
                :src="require('./../../../assets/Images/mcbg.jpg')"
                class="align-center"
              >
              <CustomerReviews :reviews="reviews" />
              </v-img>
            </v-flex>
          </v-layout>
        <!-- </v-img> -->
      </v-flex>
      <!-- <v-flex xs12 md12 lg12 xl12 py-2 pb-16 v-if="video">
        <Video :video="video" />
      </v-flex>

      <v-flex xs12 md12 lg12 xl12 py-2 pb-16 v-if="reviews.length > 0">
        <CustomerReviews :reviews="reviews" />
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Banner from "./topBanner";
import AboutUs from "./aboutUs";
import FeaturedProducts from "./featuredProducts";
import Video from "./video";
import CustomerReviews from "./customerReviews";
export default {
  components: {
    Banner,
    AboutUs,
    FeaturedProducts,
    Video,
    CustomerReviews,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      value: null,
      timeout: 5000,
      msg: null,
      banner: null,
      about: null,
      video: null,
      reviews: [],
      videoContent:null,
    };
  },
  beforeMount() {
    this.getHome();
  },
  methods: {
    getHome() {
      this.appLoading = true;
      axios({
        url: "/aboutus/view",
        method: "GET",
      })
        .then((response) => {
          this.appLoading = false;
          this.about = response.data.aboutus;
          this.videoContent = response.data.video
          this.banner = response.data.data;
          this.video = response.data.video.videourl;
          if (response.data.reviews) this.reviews = response.data.reviews;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.rate {
  font-family: sofiaProLight;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
}
.name {
  font-family: sofiaProSemiBold;
  font-size: 20px;
  color: #1a1919;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-select__selections {
  min-height: 10px;
}
</style>
